<template>
  <v-container
    tag="section"
    fluid
  >
    <base-material-card
      icon="mdi-alarm-multiple"
      :title="`Wettkämpfe ${lname}`"
      color="red"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="filter"
            label="Filter"
          />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.mdAndDown" cols="12">
          <v-range-slider
            v-model="date"
            :tick-labels="dates_format"
            :value="dates"
            min="0"
            :max="dates.length - 1"
            ticks="always"
            tick-size="4"
          />
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="6">
          <v-select label="Wettkämpfe anzeigen von" @change="(a) => set(0, a)" :value="dates_format[date[0]]" :items="dates_format" />
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="6">
          <v-select label="Wettkämpfe anzeigen bis" @change="(a) => set(1, a)" :value="dates_format[date[1]]" :items="dates_format" />
        </v-col>
        <v-col cols="12">
          <p>Klicken Sie auf eine Begegnung um die Live-Anzeige bzw. das Endergebnis anzuzeigen.</p>
        </v-col>
      </v-row>

      <v-data-table
        :items="events_filtered"
        :headers="[
          { text: 'Beginn', value: 'startDate', sortable: false },
          { text: 'Liga', value: 'league.shortName', sortable: false },
          { text: 'Mannschaften', value: 'mannschaften', sortable: false },
          { text: 'Punkte', value: 'final', sortable: false, align: 'center' },
          { text: 'TP', value: 'tp', sortable: false, align: 'center' },
          { text: 'Hinweise', value: 'publicComment', sortable: false, align: 'center' },
          { text: 'Halle', value: 'venue', sortable: false, align: 'center' }
        ]"
        :search="filter"
        :mobile-breakpoint="0"
        :items-per-page="50"
        @click:row="open"
      >
        <template #item.startDate="{item}">
          <i v-if="item.status === 'EventPostponed'"><span style="text-decoration:line-through;">{{ item.startDate | dateformat('DD.MM.YYYY') }}</span><br>wird verschoben,<br>Termin noch offen</i>
          <span v-else :inner-html.prop="item.startDate | dateformat('dddd<br>DD.MM.YYYY<br>HH:mm')" />
        </template>
        <template #item.mannschaften="{item}">
          <span :inner-html.prop="item.mannschaften.join('\n') | nl2br" style="text-transform: none; white-space: nowrap;"/>
        </template>
        <template #item.final="{item}">
          <div v-for="(e, i) in item.teams" :key="`${item._id}-${i}`">
            <template v-if="e.result !== null && e.result !== undefined">{{ e.result.final | float2_0 }}</template>
          </div>
        </template>
        <template #item.tp="{item}">
          <div v-for="(e, i) in item.teams" :key="`${item._id}-${i}`">
            {{ e.result.tp }}
          </div>
        </template>
        <template #item.publicComment="{item}">
          <v-tooltip v-if="item.publicComment" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small fab text>
                <v-icon>far fa-info-circle</v-icon>
              </v-btn>
            </template>
            <span>
            {{ item.publicComment }}
          </span>
          </v-tooltip>
        </template>
        <template #item.venue="{item}">
          <v-tooltip v-if="item.venue" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small fab text @click.stop="openmaps(item.venue.address)">
                <v-icon>far fa-circle-h</v-icon>
              </v-btn>
            </template>
            <span>
              {{ item.venue.name }}<br>
              {{ item.venue.address.streetAddress }} {{ item.venue.address.streetNumber }}<br>
              {{ item.venue.address.postalCode }} {{ item.venue.address.addressLocality }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { deleteQuery, useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'
import Vue from 'vue'

const query = `
  _id
  ... on StbW2023League {
    parent { ... on StbW2023 { _id name } }
    name
    shortName
    order
    events {
      _id
      startDate
      publicComment
      status
      teams {
        team { _id name }
        result: results(discipline: null) { final tp }
      }
      venue {
        _id
        name
        address { streetAddress streetNumber postalCode addressLocality }
      }
    }
  }
`

export default {
  name: 'start',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    filter: '',
    date: [0, 0]
  }),

  computed: {
    lname () {
      return this.EventFind?.[0]?.parent?.name || ''
    },
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...curr.events.map(e => ({
          ...e,
          league: curr,
          teams: e.teams?.map(t => ({
            _id: t.team._id,
            name: t.team.name,
            result: t.result?.[0] || { final: 0, tp: '' }
          })).sort((a, b) => {
            if (a.result?.tp === b.result?.tp) return a.name < b.name ? -1 : 1
            if (!a.result?.tp) return 1
            if (!b.result?.tp) return -1
            return a.result.tp < b.result.tp ? 1 : -1
          }) || []
        })))
        return acc
      }, []).map(e => ({
        ...e,
        mannschaften: e.teams.map(t => t.name),
        final: e.teams.map(t => t.result.final),
        tp: e.teams.map(t => t.result.tp)
      })).sort((a, b) => {
        if (a.startDate !== b.startDate) return a.startDate < b.startDate ? -1 : 1
        if (a.league.order !== b.league.order) return a.league.order < b.league.order ? -1 : 1
      })
    },
    dates () {
      if (this.events.length === 0) return ['']
      return this.events.map(e => e.startDate?.split(' ')?.[0]).sort().filter((d, i, arr) => !arr.find((d2, j) => j < i && d === d2))
    },
    dates_format () {
      return this.dates.map(d => this.$options.filters.dateformat(d, 'DD.MM.'))
    },
    events_filtered () {
      const start = this.dates[this.date[0]] + ' 00:00:00'
      const end = this.dates[this.date[1]] + ' 23:59:39'

      return this.events.filter(e => e.startDate >= start && e.startDate <= end)
    }
  },

  methods: {
    open (item) {
      this.$router.push(`/view/${item._id}`)
    },
    openmaps (address) {
      const a = `${address.streetAddress} ${address.streetNumber}, ${address.postalCode}, ${address.addressLocality}`
      window.open(`https://www.google.de/maps/place/${a.replaceAll(' ', '+')}`, '_blank')
    },
    set (i, val) {
      const index = this.dates_format.findIndex(d => d === val)
      if (index === -1) return
      Vue.set(this.date, i, index)
    }
  },

  watch: {
    dates () {
      const now = (new Date()).toISOString().split('T')[0]

      const index = this.dates.findIndex(d => d >= now)
      if (index !== -1) {
        if (this.dates[index] === now) {
          this.date = [Math.max(index, 0), this.dates.length - 1]
        } else {
          this.date = [Math.max(index - 1, 0), this.dates.length - 1]
        }
      } else {
        this.date = [0, this.dates.length - 1]
      }
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID) { EventUpdate(parent: $parent) { ${query} }}
        `
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}

</script>

<style scoped>

</style>
